export default [
  {
    title: 'home.label.dashboard',
    icon: 'far fa-house',
    route: 'dashboard'
  },
  {
    title: 'home.label.cloud_service',
    icon: 'far fa-cloud',
    route: 'cloud-vps',
    children: [
      {
        title: 'Danh Sách',
        icon: 'far fa-grid-2',
        route: 'cloud-vps'
      },
      {
        title: 'Kho',
        icon: 'far fa-folder',
        route: 'cloud-vps-storage'
      },
      {
        title: 'home.label.plan_setting',
        icon: 'far fa-box',
        route: 'plan-setting'
      }
    ],
  },
  {
    title: 'Proxy',
    icon: 'far fa-network-wired',
    route: 'cloud-vps',
    children: [
      {
        title: 'Danh Sách',
        icon: 'far fa-grid-2',
        route: 'proxy-service'
      },
      {
        title: 'Kho',
        icon: 'far fa-folder',
        route: 'proxy-storage'
      },
      {
        title: 'home.label.plan_setting',
        icon: 'far fa-box',
        route: 'proxy-plan-setting'
      }
    ],
  },
  {
    title: 'Hoá đơn',
    icon: 'far fa-file-invoice-dollar',
    route: 'invoices'
  },
  {
    title: 'home.label.user',
    icon: 'far fa-users',
    route: 'user'
  },
  {
    title: 'RGC',
    icon: 'far fa-globe',
    route: 'rgc'
  },
  {
    title: 'Addon',
    icon: 'far fa-plus-square',
    route: 'addon'
  },
  {
    title: 'QR Code Generator',
    icon: 'far fa-qrcode',
    route: 'qr-code-generator'
  },
  {
    title: 'Cài đặt',
    icon: 'far fa-cog',
    route: 'setting'
  }
  // {
  //   title: 'home.label.dedicated_list',
  //   icon: 'ServerIcon'
  // }
]
